
import React, {useEffect} from "react";

const U1 = () => {

    useEffect(() => {
        window.location.href = "https://www.fti.uz/uz/";
    }, []);
    return (
        <>

        </>
    );
};

export default U1;
