
import React, {useEffect} from "react";

const U3 = () => {

    useEffect(() => {
        window.location.href = "https://iplt.uz/uz";
    }, []);
    return (
        <>

        </>
    );
};

export default U3;
