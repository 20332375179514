
import React, {useEffect} from "react";

const U2 = () => {

    useEffect(() => {
        window.location.href = "https://instmech.academy.uz/uz";
    }, []);
    return (
        <>

        </>
    );
};

export default U2;
