// University Routes
// Rector Tabrigi
export const CONGRATULATION = "congratulations";
//Universitet nizomi
export const CHARTER = "charters";
// Rector add reception
export const ADD_RECEPTIONS = "add/receptions";
//Meyori hujjatlar
export const ARCHITECTURAL = "architecturals";
// Universitet tarixi
export const HISTORY = "histors";
// Rekvizitlar
export const GRADUATES = "graduats";